import { formatDate } from '@angular/common';
import { dateRangeValueConverter } from '../../../global.variable';
export const paymentListDataTableDetail: any = [
  {
    header: 'Subscriber',
    name: 'subscriber_name',
    sortKey: 'subscriber_name',
    searchKey: 'subscriber_name',
  },
  {
    header: 'Paid By',
    name: 'invoice_paid_by',
  },

  {
    header: 'Invoice Date',
    name: 'invoice_created_at',
    datetimeObj: true,
    sortKey: 'invoice_created_at',
    dateRangeKey: 'invoice_created_at',
  },
  {
    header: 'Invoice Amount(AUD)',
    name: 'invoice_amount',
    sortKey: 'invoice_amount',
  },
  {
    header: 'Invoice Card',
    nestedValue: (row: any) => {
      return row?.['invoice_card_details']?.['last_4'];
    },
  },
  {
    header: 'Invoice Number',
    name: 'invoice_num',
  },
  {
    header: 'Square Date',
    name: 'square_created_at',
    datetimeObj: true,
    sortKey: 'square_created_at',
    dateRangeKey: 'square_created_at',
  },
  {
    header: 'Square Amount(AUD)',
    name: 'square_amount',
    sortKey: 'square_amount',
  },
  {
    header: 'Square Status',
    name: 'square_status',
  },
  {
    header: 'Square Card',
    nestedValue: (row: any) => {
      return row?.['square_card_details']?.['last_4'];
    },
  },
];
export const invoiceListDataTableDetail = [
  {
    header: 'Subscriber',
    name: 'subscriber_company_name',
    searchKey: 'subscriber_company_name',
    sortKey: 'subscriber_company_name',
  },
  {
    header: 'Invoice Number',
    name: 'invoice_num',
  },
  {
    header: 'Invoice Date',
    name: 'generated_date',
    datetimeObj: true,
    sortKey: 'generated_date',
    dateRangeKey: 'generated_date',
  },
  {
    header: 'Amount(AUD)',
    name: 'amount',
    sortKey: 'amount',
  },
  {
    header: 'Misc Amount(AUD)',
    nestedValue: (row: any) => {
      return (
        (row?.user_transactions?.allotted_slots || 0) *
        (row?.user_transactions?.user_price || 0)
      );
    },
  },
  {
    header: 'Start',
    name: 'invoice_period_start',
    datetimeObj: true,
    sortKey: 'invoice_period_start',
    dateTimeFormat: 'd MMM y',
  },
  {
    header: 'End',
    name: 'invoice_period_end',
    datetimeObj: true,
    sortKey: 'invoice_period_end',
    dateTimeFormat: 'd MMM y',
  },
  {
    header: 'Invoice Card',
    name: 'card_last4',
  },
];
export const clientScheduledReportTable = [
  {
    header: 'Report Name',
    name: 'report_name',
  },
  {
    header: 'Subscriber',
    name: 'subscriber_name',
    searchKey: 'company__subscriber__name',
    sortKey: 'company__subscriber__name',
  },

  {
    header: 'Client',
    name: 'company_name',
    searchKey: 'company__company_name',
    sortKey: 'company__company_name',
  },
  {
    header: 'Site',
    name: 'site_name',
    searchKey: 'site__company_name',
    sortKey: 'site__company_name',
  },
  {
    header: 'User Mail Time',
    name: 'mail_time',
  },
  {
    header: 'Local Mail Time',
    name: 'utc_mail_time',
    datetimeObj: true,
  },
  {
    header: 'Is Today',
    name: 'is_today',
  },
  {
    header: 'Last Ran At',
    name: 'last_ran_at',
    datetimeObj: true,
    sortKey: 'last_ran_at',
    dateRangeKey: 'last_ran_at',
  },
  {
    header: 'Repeat Type',
    name: 'repeat_type',
    searchKey: 'schedule__repeat_type',
    sortKey: 'schedule__repeat_type',
  },
  {
    header: 'Start Day',
    name: 'start_day',
    datetimeObj: true,
    dateRangeKey: 'schedule__start_day',
    dateTimeFormat: 'd MMM y',
    sortKey: 'schedule__start_day',
  },
];
export const superUserDailyReportTable = {
  subscriber: [
    {
      header: 'Created At',
      name: 'created_date',

      sortKey: 'created_date',
    },
    {
      header: 'Subscriber',
      name: 'name',
      sortKey: 'name',
    },
  ],
  checkpointScan: [
    {
      header: 'Subscriber',
      name: 'subscriber_name',
      sortKey: 'subscriber_name',
    },
    {
      header: 'Count',
      name: 'count',
      sortKey: 'count',
    },
  ],
  completedJob: [
    {
      header: 'Subscriber',
      name: 'subscriber_name',
      sortKey: 'subscriber_name',
    },
    {
      header: 'Count',
      name: 'count',
      sortKey: 'count',
    },
  ],
  trialAccounts: [
    {
      header: 'Subscriber',
      name: 'name',
      sortKey: 'name',
    },
    {
      header: 'Count',
      name: 'count',
      sortKey: 'count',
    },
  ],
  statusDiff: [
    {
      header: 'Subscriber',
      name: 'name',
      sortKey: 'name',
    },
    {
      header: 'Count',
      name: 'count',
      sortKey: 'count',
    },
    {
      header: 'Diff',
      name: 'diff',
    },
  ],
};

export const companyTableDetails = {
  mobileTable: {
    individualDetails: [
      {
        name: 'action',
        cardRowStyle: {
          'font-weight': 'bolder',
        },
      },
      {
        name: 'updated_at',

        datetimeObj: true,
        cardRowStyle: {
          'font-size': 'small',
        },
      },

      {
        name: 'user_name',
        cardRowStyle: {
          'font-size': 'small',
        },
      },
    ],
  },
  desktopTable: [
    {
      header: 'Event Time',
      name: 'updated_at',
      datetimeObj: true,
      dateRangeKey: 'updated_at',
      sortKey: 'updated_at',
      prevDays: 30,
    },

    {
      header: 'Action',
      name: 'action',
      searchKey: 'action',
    },
    {
      header: 'Value',
      name: 'detail',
    },

    {
      header: 'Event By',
      name: 'user_name',
      sortKey: 'user_name',
      searchKey: 'user_name',
    },
  ],
};
export const userGroupTableDetails = {
  mobileTable: {
    individualDetails: [
      {
        name: 'action',
        cardRowStyle: {
          'font-weight': 'bolder',
          'float': 'right',
        },
      },
      {
        name: 'user_group_name',
        cardRowStyle: {
          'font-weight': 'bolder',
        },
      },

      {
        name: 'updated_at',
        datetimeObj: true,
        dateRangeKey: 'updated_at',
        ...getDateTimeRange(),
        cardRowStyle: {
          'font-size': 'small',
        },
      },

      {
        name: 'user_name',

        cardRowStyle: {
          'font-size': 'small',
        },
      },
    ],
  },
  desktopTable: [
    {
      header: 'Event Time',
      name: 'updated_at',
      datetimeObj: true,
      sortKey: 'updated_at',
      dateRangeKey: 'updated_at',
      ...getDateTimeRange(),
    },

    {
      header: 'User Group',
      name: 'user_group_name',
    },
    {
      header: 'Action',
      name: 'action',
    },
    {
      header: 'Value',
      name: 'detail',
    },

    {
      header: 'Event By',
      name: 'user_name',
      sortKey: 'user_name',
      searchKey: 'user_name',
    },
  ],
};

export const userLastLocation = {
  mobileTable: {
    individualDetails: [
      {
        name: 'action',
        cardRowStyle: {
          float: 'right',
        },
      },
      {
        name: 'user_name',
        cardRowStyle: {
          'font-weight': 'bolder',
        },
      },

      {
        name: 'updated_at',

        datetimeObj: true,
        cardRowStyle: {
          'font-size': 'small',
        },
      },
    ],
  },
  desktopTable: [
    {
      header: 'Event Time',
      name: 'updated_at',
      datetimeObj: true,
      dateRangeKey: 'updated_at',
      sortKey: 'updated_at',
    },

    {
      header: 'Action',
      name: 'action',
    },

    {
      header: 'Guard',
      name: 'user_name',
      sortKey: 'user_name',
      searchKey: 'user_name',
    },
  ],
};
export function getDateTimeRange(prevDays: number = 1) {
  const today = new Date();
  today.setDate(new Date().getDate() - prevDays);
  const dateRange = {
    start: today,
    end: new Date(),
  };
  return {
    dateRange: dateRange,
    dateRangeValue: `${dateRangeValueConverter(
      dateRange?.start
    )} - ${dateRangeValueConverter(dateRange?.end)}`,
  };
}

// Used for scan checkpoint history desktop and mobile
export const checkPointScanTable = {
  desktopTable: [
    {
      header: '',
      name: 'updated_at',
      datetimeObj: true,
      dateRangeKey: 'updated_at',
      sortKey: 'updated_at',
      ...getDateTimeRange(),
    },
    {
      header: '',
      name: 'company_name',
    },
    {
      header: '',
      name: 'detail',
    },

    {
      header: '',
      name: 'user_name',
      sortKey: 'user_name',
      searchKey: 'user_name',
    },
  ],
  mobileTable: {
    individualDetails: [
      {
        name: 'company_name',
        cardRowStyle: {
          'font-size': 'small',
          'font-weight': 'bold',
        },
      },
      {
        name: 'detail',
        cardRowStyle: {
          'font-size': 'small',
        },
      },
      {
        name: 'updated_at',
        datetimeObj: true,
        dateRangeKey: 'updated_at',
        ...getDateTimeRange(),
        cardRowStyle: {
          'font-size': 'small',
          'float': 'right',
        },
      },

      {
        name: 'user_name',

        cardRowStyle: {
          'font-size': 'small',
          'font-weight': 'bolder',
        },
      },
    ],
  },
};
export const checkPointHistoryTable = {
  desktopTable: [
    {
      header: 'On',
      name: 'updated_at',
      datetimeObj: true,
      dateRangeKey: 'updated_at',
      ...getDateTimeRange(),
      sortKey: 'updated_at',
    },
    {
      header: 'Checkpoint',
      name: 'check_point_key',
    },
    {
      header: 'Action',
      name: 'action',
    },
    {
      header: 'Change',
      name: 'detail',
    },

    {
      header: 'Event By',
      name: 'user_name',
      sortKey: 'user_name',
      searchKey: 'user_name',
    },
  ],
  mobileTable: {
    individualDetails: [
      {
        name: 'action',
        cardRowStyle: {
          'font-weight': 'bolder',
          'float': 'right',
        },
      },
      {
        name: 'check_point_key',
        cardRowStyle: { 'font-size': 'small' },
      },
      {
        name: 'detail',
        cardRowStyle: {},
      },

      {
        name: 'updated_at',
        dateRangeKey: 'updated_at',
        ...getDateTimeRange(),
        datetimeObj: true,
        cardRowStyle: {
          'font-size': 'small',
        },
      },

      {
        name: 'user_name',

        cardRowStyle: {
          'font-size': 'small',
        },
      },
    ],
  },
};
//Used for job history desktop and mobile
export const jobHistoryTable = {
  desktopTable: [
    {
      header: '',
      name: 'updated_at',
      datetimeObj: true,
      dateRangeKey: 'updated_at',
      sortKey: 'updated_at',
    },
    {
      header: '',
      name: 'event_key',
    },

    {
      header: '',
      name: 'user_name',
      sortKey: 'user_name',
      searchKey: 'user_name',
    },
  ],
  mobileTable: {
    individualDetails: [
      {
        name: 'updated_at',
        datetimeObj: true,
        cardRowStyle: {
          'font-size': 'small',
          'font-weight': 'bold',
        },
      },
      {
        name: 'action',
        cardRowStyle: {
          'font-size': 'small',
        },
      },
      {
        name: 'user_name',

        cardRowStyle: {
          'font-size': 'small',
          'float': 'right',
        },
      },
    ],
  },
};

// beta user feedback tableDetails
export const betaFeedbackTable = {
  desktopTable: [
    {
      header: 'Submitted',
      name: 'created_at',
      datetimeObj: true,
      sortKey: 'created_at',
    },

    {
      header: 'Description',
      name: 'feedback',
    },

    {
      header: 'Status',
      name: 'status',
    },
  ],
  mobileTable: {
    individualDetails: [
      {
        name: 'status',
        // prefix: 'Status:',
        cardRowStyle: {
          // 'float': 'right',
          'font-size': '0.8rem',
          'font-weight': 'bold',
        },
      },

      {
        name: 'created_at',
        prefix: 'Submitted:',
        datetimeObj: true,
        cardRowStyle: {
          'font-size': 'small',
        },
      },

      {
        name: 'feedback',
        cardRowStyle: {
          'margin-top': '1rem',
        },
      },
    ],
  },
};
// Used for Patrol History desktop and mobile
export const userUpdateTable = {
  desktopTable: [
    {
      header: 'Date',
      name: 'updated_at',
      datetimeObj: true,
      dateRangeKey: 'updated_at',
      sortKey: 'updated_at',
    },
    {
      header: 'Event Type',
      name: 'action',
    },
    {
      header: 'Company',
      name: 'company_name',
    },
    {
      header: 'Checkpoint',
      columnDataStyle: {
        'white-space': 'pre-line',
      },
      nestedValue: (row: any) => {
        return [0, 6].includes(row?.event_action)
          ? [row?.detail, row?.address].join('\n')
          : '';
      },
    },
    {
      header: 'Guard',
      name: 'user_name',
      sortKey: 'user_name',
      searchKey: 'user_name',
    },
  ],
  mobileTable: {
    individualDetails: [
      {
        name: 'updated_at',
        datetimeObj: true,
        cardRowStyle: {
          'font-size': 'small',
        },
      },
      {
        name: 'user_name',
        cardRowStyle: {
          'font-size': 'small',
        },
      },
      {
        name: 'action',
        cardRowStyle: {
          'font-size': 'small',
          'font-weight': 'bold',
          'text-transform': 'uppercase',
        },
      },
      {
        name: 'company_name',
        cardRowStyle: {
          'font-size': 'small',
        },
      },
      {
        name: 'detail',
        cardRowStyle: {
          'font-size': 'small',
        },
      },
    ],
  },
};
export const sosAlertDetailView = {
  desktopTable: [
    {
      header: 'Date',
      name: 'updated_at',
      datetimeObj: true,
      dateRangeKey: 'updated_at',
      sortKey: 'updated_at',
    },

    {
      header: 'Acknowledgment',
      columnDataStyle: {
        'white-space': 'pre-line',
      },
      nestedValue: (row: any) => {
        return row?.acknowledge_info?.updated_at
          ? [
              row?.acknowledge_info?.user_name,
              formatDate(
                row?.acknowledge_info?.updated_at,
                'd MMM y HH:mm',
                'en_US'
              ),
            ].join('\n')
          : '';
      },
    },
    {
      header: 'Guard',
      name: 'user_name',
      sortKey: 'user_name',
      searchKey: 'user_name',
    },
  ],
  mobileTable: {
    individualDetails: [
      {
        name: 'updated_at',
        datetimeObj: true,
        cardRowStyle: {
          'font-size': 'small',
        },
      },
      {
        name: 'user_name',
        cardRowStyle: {
          'font-size': 'small',
        },
      },

      {
        nestedValue: (row: any) => {
          return row?.acknowledge_info?.updated_at
            ? `Acknowledged by ${
                row?.acknowledge_info?.user_name
              } @ ${formatDate(
                row?.acknowledge_info?.updated_at,
                'd MMM y HH:mm',
                'en_US'
              )}`
            : '';
        },
      },
    ],
  },
};
