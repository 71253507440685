import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { isLargeScreen, mapLayers } from '../../../../global.variable';
import { LoadingSpinnerService } from '../../services/loading-spinner.service';
declare let L: any;

@Component({
  selector: 'gtapp-map-leaflet',
  templateUrl: './map-leaflet.component.html',
  styleUrl: './map-leaflet.component.scss',
})
export class MapLeafletComponent implements OnInit, OnChanges, OnDestroy {
  @Input('latLon') latLon: any;
  @Input('mapId') mapId: any;

  @Input('changableRadius') changableRadius = false;
  @Input('cursorDraggable') cursorDraggable = false;
  @Input('mapRadiusParams') mapRadiusParams: any;
  @Input('scannedCheckpointDetails') scannedCheckpointDetails: any;

  @Output('emitData') emitData = new EventEmitter();
  @Output('updateMinDistance') updateMinDistance = new EventEmitter();

  mapData: any;

  default_zoom = 5;
  incremental_zoom = 2;
  baselayers: any = mapLayers;
  defaultLayer = 'Default';

  pwaApp: boolean = Boolean(
    window.matchMedia('(display-mode: standalone)').matches
  );
  // geo fence component
  minKm: number = 10;
  maxKm: number = 1000;
  nearestKmValue: number = 100;

  leafletCircle: any;

  largeView: Boolean = isLargeScreen;

  constructor(
    private spinnerService: LoadingSpinnerService,
    private cdr: ChangeDetectorRef
  ) {}

  getMarkerPin(color = 'rgb(255, 127, 0)') {
    const svgIcon = `
    <svg width="60" height="60" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 2C8.13 2 5 5.13 5 9C5 13.25 10.25 20 11.08 21.08C11.37 21.44 11.68 21.74 12 22C12.32 21.74 12.63 21.44 12.92 21.08C13.75 20 19 13.25 19 9C19 5.13 15.87 2 12 2ZM12 11.5C10.62 11.5 9.5 10.38 9.5 9C9.5 7.62 10.62 6.5 12 6.5C13.38 6.5 14.5 7.62 14.5 9C14.5 10.38 13.38 11.5 12 11.5Z" fill="${color}"/>
    </svg>
  `;
    return L.divIcon({
      className: 'custom-pin',
      iconSize: [60, 60], // Increased size of the icon
      iconAnchor: [30, 60], // Center the icon at the bottom
      popupAnchor: [0, -60], // Adjust the popup position
      html: svgIcon,
    });
  }

  getUserPin() {
    const svgIcon = `
<svg width="60" height="60" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle r="4" cx="12" cy="12" fill="#4285f4" stroke="#d3e0f6" stroke-width="3"/>
    </svg>
  `;

    return L.divIcon({
      className: 'custom-pin',
      iconSize: [60, 60], // Increased size of the icon
      iconAnchor: [30, 30], // Center the icon at the bottom
      popupAnchor: [30, -30], // Adjust the popup position
      html: svgIcon,
    });
  }

  ngOnInit(): void {
    try {
      const geoFenceMinRadius: any =
        sessionStorage.getItem('geoFenceMinRadius');
      if (geoFenceMinRadius) {
        this.nearestKmValue = Number(geoFenceMinRadius);
      }
    } catch (error) {}

    if (this.mapId == undefined) {
      this.mapId = 'map';
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    const savedLayer = localStorage.getItem('selectedLayer');
    if (savedLayer && this.baselayers[savedLayer]) {
      this.defaultLayer = savedLayer;
    }
    if (this.mapData) {
      this.mapData.remove();
      this.mapData = null;
    }

    setTimeout(async () => {
      await this.loadMap();
    }, 100);
  }

  async loadMap() {
    if (this.mapId == undefined) {
      this.mapId = 'map';
    }

    if (this.mapRadiusParams) {
      this.minKm =
        this.mapRadiusParams?.minKm >= 0 ? this.mapRadiusParams?.minKm : 5;
      this.maxKm = this.mapRadiusParams?.maxKm || 1000;
      this.nearestKmValue =
        this.mapRadiusParams?.nearestKmValue >= 0
          ? this.mapRadiusParams?.nearestKmValue
          : 100;
    }
    const osmLayer = this.getTileLayer(this.defaultLayer);

    let center = L.latLng(this.latLon.lat, this.latLon.lon);
    var container: any = L.DomUtil.get(this.mapId);
    if (container != null) {
      container._leaflet_id = null;
    }

    this.mapData = L.map(this.mapId, {
      center: center,
      zoom: 16,
      dragging: false,
      layers: [osmLayer],
      zoomControl: !Boolean(this.latLon?.markerTooltip),
      fullscreenControl: !Boolean(this.latLon?.markerTooltip),
      fullscreenControlOptions: {
        // optional
        title: 'Show me the fullscreen !',
        titleCancel: 'Exit fullscreen mode',
      },
    });

    this.baselayers[this.defaultLayer].addTo(this.mapData);

    L.control.layers(this.baselayers).addTo(this.mapData);

    const markerIcon = this.latLon?.markerTooltip
      ? this.getUserPin()
      : this.getMarkerPin();

    const markerCluster = new L.MarkerClusterGroup();
    var app = this;
    let _mar: any;

    if (this.latLon?.markerTooltip || this.latLon?.gpsToolTip) {
      _mar = L.marker(new L.LatLng(this.latLon.lat, this.latLon.lon), {
        icon: markerIcon,
        draggable: this.cursorDraggable,
      })
        .bindTooltip(this.latLon?.markerTooltip || this.latLon?.gpsToolTip, {
          permanent: true,
          direction: 'bottom',
          offset: this.latLon?.gpsToolTip ? [0, 0] : [0, 15],
        })
        .openTooltip();
    } else {
      _mar = L.marker(new L.LatLng(this.latLon.lat, this.latLon.lon), {
        icon: markerIcon,
        draggable: this.cursorDraggable,
      });
    }

    _mar.on('dragend', (event: any) => {
      var marker = event.target;
      var position = marker.getLatLng();
      app.emitData.emit(position);
      this.latLon = { lat: position.lat, lon: position.lng };
      marker.setLatLng(new L.LatLng(this.latLon.lat, this.latLon.lon), {
        draggable: false,
      });

      app.mapData.panTo(new L.LatLng(this.latLon.lat, this.latLon.lon));
      if (this.changableRadius || this.latLon?.geoFenceRadius) {
        this.mapData?.removeLayer(this.leafletCircle);
        this.nearestKmValue =
          this.latLon?.geoFenceRadius || this.nearestKmValue;

        this.leafletCircle = L.circle(
          new L.LatLng(this.latLon.lat, this.latLon.lon),
          {
            color: 'red',
            fillOpacity: 0,
            radius: this.nearestKmValue,
            weight: 1,
          }
        )?.addTo(this.mapData);

        setTimeout(() => {
          this.mapData?.fitBounds(this.leafletCircle.getBounds());
        }, 100);
      }
    });

    this.mapData.on('baselayerchange', (e: any) => {
      if (this.baselayers?.[e.name]) {
        this.defaultLayer = e.name;
        // Save the selected layer to local storage
        localStorage.setItem('selectedLayer', e.name);
      }
    });

    markerCluster.addLayer(_mar);
    if (this.changableRadius || this.latLon?.geoFenceRadius) {
      this.nearestKmValue = this.latLon?.geoFenceRadius || this.nearestKmValue;
      this.leafletCircle = L.circle([this.latLon.lat, this.latLon.lon], {
        color: 'red',
        fillOpacity: 0,
        radius: this.nearestKmValue,
        weight: 1,
      }).addTo(this.mapData);
      setTimeout(() => {
        this.mapData.fitBounds(this.leafletCircle.getBounds());
      }, 300);
    }
    if (
      this.scannedCheckpointDetails?.scan_detail?.lat &&
      this.scannedCheckpointDetails?.scan_detail?.lon
    ) {
      var circle = L.circle(
        [
          this.scannedCheckpointDetails?.scan_detail?.lat,
          this.scannedCheckpointDetails?.scan_detail?.lon,
        ],
        {
          color: 'red',
          fillOpacity: 0,
          radius: this.scannedCheckpointDetails?.min_distance,
          weight: 1,
        }
      ).addTo(this.mapData);
      let cpMarker: any;
      if (this.latLon?.markerTooltip) {
        cpMarker = L.marker(
          new L.LatLng(
            this.scannedCheckpointDetails?.scan_detail?.lat,
            this.scannedCheckpointDetails?.scan_detail?.lon
          ),
          {
            icon: this.getMarkerPin('rgb(255,0,0)'),
            draggable: false,
          }
        );
      } else {
        cpMarker = L.marker(
          new L.LatLng(
            this.scannedCheckpointDetails?.scan_detail?.lat,
            this.scannedCheckpointDetails?.scan_detail?.lon
          ),
          {
            icon: this.getMarkerPin('rgb(255,0,0)'),
            draggable: false,
          }
        )
          .bindTooltip(this.scannedCheckpointDetails?.name, {
            permanent: true,
            direction: 'bottom',
          })
          .openTooltip();
      }

      markerCluster.addLayer(cpMarker);
    }

    this.mapData.addLayer(markerCluster);
    this.mapData.fitBounds(markerCluster.getBounds());

    if (this.scannedCheckpointDetails?.scanDistanceInfo?.scanDistance > 1000) {
      // conditional zooming out if scan distance > 1 km

      const currentZoom = this.mapData.getZoom();
      this.mapData.setZoom(currentZoom - 1);
    }

    if (this.pwaApp == true) {
      let embeddedLinkElement: any = document.querySelectorAll(
        '.leaflet-control-attribution.leaflet-control'
      );
      if (embeddedLinkElement) {
        embeddedLinkElement.forEach((element: any) => {
          element.classList.add('disable-pointer-events');
        });
      }
    }

    this.spinnerService.hide();
  }

  ngOnDestroy(): void {
    if (this.mapData) {
      this.mapData.remove();
      this.mapData = null;
    }
    this.defaultLayer = 'Default';
    this.mapId = undefined;
  }
  changeGeoFenceRadius() {
    setTimeout(() => {
      this.nearestKmValue = this.adjustMinDistanceValue(this.nearestKmValue);
      this.cdr.detectChanges();

      if (this.changableRadius && this.mapData) {
        this.mapData?.removeLayer(this.leafletCircle);
        this.leafletCircle = L.circle([this.latLon.lat, this.latLon.lon], {
          color: 'red',
          fillOpacity: 0,
          radius: this.nearestKmValue,
          weight: 1,
        }).addTo(this.mapData);
        this.mapData.fitBounds(this.leafletCircle.getBounds());
        this.updateMinDistance.emit(this.nearestKmValue);
      }
    }, 100);
  }
  onSliderChange() {
    if (this.changableRadius && this.mapData) {
      this.mapData?.removeLayer(this.leafletCircle);
      this.leafletCircle = L.circle([this.latLon.lat, this.latLon.lon], {
        color: 'red',
        fillOpacity: 0,
        radius: this.nearestKmValue,
        weight: 1,
      }).addTo(this.mapData);
      this.mapData.fitBounds(this.leafletCircle.getBounds());
    }
  }
  adjustMinDistanceValue(value: number): number {
    if (value > this.maxKm) {
      return this.maxKm;
    } else {
      return value;
    }
  }
  getTileLayer(layer: string) {
    switch (layer) {
      case 'Google Hybrid':
        return L.tileLayer(
          'https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',
          {
            maxZoom: 18,
            minZoom: 10,
            id: 'hybrid',
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
            attribution:
              '&copy; <a href="https://maps.google.com/help/terms_maps.html">Google Maps</a> ',
          }
        );

      case 'Google Satlite':
        return L.tileLayer(
          'https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
          {
            maxZoom: 18,
            minZoom: 10,
            id: 'satelite',
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
            attribution:
              '&copy; <a href="https://maps.google.com/help/terms_maps.html">Google Maps</a> ',
          }
        );
      default:
        return L.tileLayer(
          'https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
          {
            maxZoom: 18,
            minZoom: 10,
            id: 'default',
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
            attribution:
              '&copy; <a href="https://maps.google.com/help/terms_maps.html">Google Maps</a> ',
          }
        );
    }
  }
}
