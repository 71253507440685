import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject, Observable, timeout } from 'rxjs';
import { LoadingSpinnerService } from './loading-spinner.service';
@Injectable({
  providedIn: 'root',
})
export class DeviceInfoService {
  deviceInfoSubject = new BehaviorSubject({});

  appStatus: boolean = true;
  // param to reverse the pages status
  locationDisabled: boolean = false;

  constructor(
    private deviceService: DeviceDetectorService,
    private http: HttpClient,
    private spinnerService: LoadingSpinnerService
  ) {
    this.onLineOfflineCheck();
    window.addEventListener('online', () => {
      // adding a delay so that the device gets connected properly before calling the server
      setTimeout(() => {
        this.onLineOfflineCheck();
      }, 2000);
    });
    window.addEventListener('offline', () => {
      this.onLineOfflineCheck();
    });
    this.deviceInfoSubject.subscribe((value: any) => {
      if ('appStatus' in value) {
        this.appStatus = value?.appStatus;
      }
    });
  }
  onLineOfflineCheck() {
    this.deviceInfoSubject.next({
      appStatus: navigator.onLine && this.appStatus,
    });
  }
  isRegistrationComplete() {
    const firstTimeSubscriber = localStorage.getItem('firstTimeSubscriber');
    return firstTimeSubscriber !== 'true';
  }
  async deviceHasGivenGpsPermission() {
    try {
      const permission = await navigator.permissions.query({
        name: 'geolocation' as PermissionName,
      });

      return Boolean(['granted'].includes(permission.state));
    } catch (error) {
      return false;
    }
  }

  async getApiHeadersAndExtras(token: string) {
    return new Promise((resolve) => {
      let headerDict: any = {
        Authorization: `Bearer ${token}`,
        timeoffset: String(new Date().getTimezoneOffset()),
      };

      try {
        const diffSeconds: any = 30; // 30 seconds
        const JsonGpsRecord = JSON.parse(
          localStorage.getItem('gpsRecord') || '{}'
        );
        const lastSavedGpsRecord = JSON.parse(
          localStorage.getItem('lastSavedGpsRecord') || '{}'
        );
        // passing gps in all the api calls to track user. if the date time is same as the previous due to simultaneous api call, discard that. and also when the gps has not changed
        if (
          JsonGpsRecord?.recorded_at &&
          (!lastSavedGpsRecord?.recorded_at ||
            (lastSavedGpsRecord?.recorded_at &&
              JsonGpsRecord?.recorded_at >=
                lastSavedGpsRecord?.recorded_at + diffSeconds &&
              (lastSavedGpsRecord?.gps?.lat !== JsonGpsRecord?.gps?.lat ||
                lastSavedGpsRecord?.gps?.lon !== JsonGpsRecord?.gps?.lon)))
        ) {
          localStorage.setItem(
            'lastSavedGpsRecord',
            JSON.stringify(JsonGpsRecord)
          );
          headerDict['gpsRecord'] = localStorage.getItem('gpsRecord');
        }
      } catch (error) {
        throw error;
      } finally {
        if (this.isRegistrationComplete()) this.updateGpsCache();
        resolve({
          headerDict: headerDict,
          reqBody: {
            delayed_event: this.appStatus && navigator.onLine,
          },
        });
      }
    });
  }

  async setIp() {
    try {
      this.getAwaitIP();
    } catch (error) {
      // Handle the error (e.g., log it or show a message)
      throw error;
    }
  }
  async handleIpApiError() {
    return await new Promise(async (resolve, reject) => {
      if (window.localStorage.getItem('ipAddress')) {
        resolve(window.localStorage.getItem('ipAddress'));
      }
      try {
        const TIMEOUT_IN_MS = 5000;
        this.backupApiCall()
          .pipe(
            timeout(TIMEOUT_IN_MS) // This will raise an error if no response is received within the timeout value
          )
          .subscribe({
            next: (resp: any) => {
              if (resp?.ipAddress) {
                window.localStorage.setItem('ipAddress', resp.ipAddress);
                resolve(resp.ipAddress);
              }
            },
            error: (err) => {
              if (err.name === 'TimeoutError') {
                resolve('null');
              } else {
                resolve('null');
              }
            },
          });
      } catch (error) {
        resolve('null');
        throw error;
      }
    });
  }
  backupApiCall(): Observable<any> {
    return this.http.get('https://freeipapi.com/api/json');
  }
  getIP(): Observable<any> {
    return this.http.get('https://jsonip.com');
  }
  async getAwaitIP() {
    const TIMEOUT_IN_MS = 5000;
    return await new Promise(async (resolve, reject) => {
      try {
        this.getIP()
          .pipe(
            timeout(TIMEOUT_IN_MS) // This will raise an error if no response is received within the timeout value
          )
          .subscribe({
            next: (resp: any) => {
              if (resp?.ip) {
                window.localStorage.setItem('ipAddress', resp.ip);
                resolve(resp.ip);
              }
            },
            error: async (err) => {
              if (err.name === 'TimeoutError') {
                resolve(await this.handleIpApiError());
              } else {
                // Handle other errors
              }
            },
          });
        setTimeout(() => {
          if (!window.localStorage.getItem('ipAddress')) {
            this.handleIpApiError();
          }
        }, 5000);
      } catch (error) {
        resolve(await this.handleIpApiError());
        throw error;
      }
    });
  }
  getGPS() {
    return this.http.get('https://freeipapi.com/api/json');
  }
  async getAwaitFreeGPS() {
    return await new Promise((resolve, reject) => {
      this.getGPS().subscribe((resp: any) =>
        resolve({
          lat: resp?.latitude,
          lon: resp?.longitude,
          source: 2,
        })
      );
    });
  }

  async watchPostionWithLowAccuracy(regardTimeOut = false) {
    return await new Promise((resolve, reject) => {
      let lowAccuracystartTime = performance.now();
      let lowAccuracyWatchId = navigator.geolocation.watchPosition(
        (position) => {
          if (position) {
            // if we are getting a postion we can stop the watchPostion then and there.
            navigator.geolocation.clearWatch(lowAccuracyWatchId);
          }
          resolve({
            lat: position.coords.latitude,
            lon: position.coords.longitude,
            source: 1,
          });
        },
        async (error) => {
          if (
            error.code === error.PERMISSION_DENIED ||
            error.code === error.TIMEOUT
          ) {
            // case 1: where user has denied location access in browser
            // case 2: when the timeout is triggered. This is happening when https://github.com/Baseflow/flutter-geolocator/issues/1312

            // stop the clear watch in that case
            navigator.geolocation.clearWatch(lowAccuracyWatchId);
            if (error.code === error.PERMISSION_DENIED) {
              this.spinnerService.hide();
              this.deviceInfoSubject.next({
                permissionStatus: 'locationDisabled',
              });
              this.locationDisabled = true;
            } else if (!regardTimeOut) {
              if (navigator.onLine) {
                resolve(await this.watchPostionWithLowAccuracy());
              } else {
                const JsonGpsRecord = JSON.parse(
                  localStorage.getItem('gpsRecord') || '{}'
                );

                if (JsonGpsRecord?.gps?.lat) {
                  resolve({
                    lat: JsonGpsRecord?.gps?.lat,
                    lon: JsonGpsRecord?.gps?.lon,
                    source: 3,
                    timeStamp: JsonGpsRecord?.recorded_at,
                    orginalSource: JsonGpsRecord?.gps?.source,
                  });
                } else {
                  this.spinnerService.hide();
                  this.deviceInfoSubject.next({
                    permissionStatus: 'locationDisabled',
                  });
                  this.locationDisabled = true;
                }
              }
            }
          } else {
            // add backup logic here. ie if endTime - startTime > x seconds find another way to fetch gps.
            if ((performance.now() - lowAccuracystartTime) / 1000 > 5) {
              //It's been 5 seconds since the app started to
              //fetch GPS with low accuracy.At this point,
              //we will fetch it from IP after clearing the low - accuracy watchPostion
              navigator.geolocation.clearWatch(lowAccuracyWatchId);

              resolve(await this.getAwaitFreeGPS());
            }
          }
        },
        {
          timeout: 1500,
          enableHighAccuracy: false,
          maximumAge: 5000,
        }
      );
    });
  }
  async watchPostionWithHighAccuracy(regardTimeOut = false) {
    return await new Promise((resolve, reject) => {
      let highAccuracystartTime = performance.now();
      let highAccuracyWatchId = navigator.geolocation.watchPosition(
        (position) => {
          if (position) {
            // if we are getting a postion we can stop the watchPostion then and there.
            navigator.geolocation.clearWatch(highAccuracyWatchId);
          }
          resolve({
            lat: position.coords.latitude,
            lon: position.coords.longitude,
            source: 0,
          });
        },
        async (error) => {
          if (
            error.code === error.PERMISSION_DENIED ||
            error.code === error.TIMEOUT
          ) {
            // case 1: where user has denied location access in browser
            // case 2: when the timeout is triggered. This is happening when https://github.com/Baseflow/flutter-geolocator/issues/1312

            // stop the clear watch in that case
            navigator.geolocation.clearWatch(highAccuracyWatchId);

            if (error.code === error.PERMISSION_DENIED) {
              if (this.isRegistrationComplete()) this.spinnerService.hide();
              // timeout error code is happening frequently which may cause doubt to users if the error message is shown.
              this.deviceInfoSubject.next({
                permissionStatus: 'locationDisabled',
              });
              this.locationDisabled = true;
            } else if (!regardTimeOut) {
              if (navigator.onLine) {
                resolve(await this.watchPostionWithLowAccuracy(regardTimeOut));
              } else {
                const JsonGpsRecord = JSON.parse(
                  localStorage.getItem('gpsRecord') || '{}'
                );

                if (JsonGpsRecord?.gps?.lat) {
                  resolve({
                    lat: JsonGpsRecord?.gps?.lat,
                    lon: JsonGpsRecord?.gps?.lon,
                    source: 3,
                    timeStamp: JsonGpsRecord?.recorded_at,
                    orginalSource: JsonGpsRecord?.gps?.source,
                  });
                } else {
                  this.spinnerService.hide();
                  this.deviceInfoSubject.next({
                    permissionStatus: 'locationDisabled',
                  });
                  this.locationDisabled = true;
                }
              }
            }
          } else {
            // add backup logic here. ie if endTime - startTime > x seconds find another way to fetch gps.
            if ((performance.now() - highAccuracystartTime) / 1000 > 5) {
              //It's been 5 seconds since the app started to
              //fetch GPS with high accuracy.At this point,
              //we will fetch it from watchPostion with low accuracy after clearing the high-accuracywatchPostion
              navigator.geolocation.clearWatch(highAccuracyWatchId);

              resolve(await this.watchPostionWithLowAccuracy(regardTimeOut));
            }
          }
        },
        {
          timeout: 1500,
          enableHighAccuracy: true,
          maximumAge: 5000,
        }
      );
    });
  }
  async getIp() {
    return new Promise(async (resolve) => {
      resolve(
        window.localStorage.getItem('ipAddress')
          ? window.localStorage.getItem('ipAddress')
          : await this.getAwaitIP()
      );
    });
  }

  async setAndGetDeviceInfo() {
    return await new Promise(async (resolve) => {
      let deviceInfo = this.deviceService.getDeviceInfo();
      window.localStorage.setItem('deviceInfo', JSON.stringify(deviceInfo));
      resolve(deviceInfo);
    });
  }
  async getDeviceInfo() {
    return new Promise(async (resolve) => {
      try {
        const deviceInfo: any = window.localStorage.getItem('deviceInfo')
          ? window.localStorage.getItem('deviceInfo')
          : await this.setAndGetDeviceInfo();
        resolve(JSON.parse(deviceInfo));
      } catch (err) {
        resolve(this.deviceService.getDeviceInfo());
      }
    });
  }
  getGpsCoordinates = async (regardTimeOut = false) => {
    return await this.watchPostionWithHighAccuracy(
      (regardTimeOut = regardTimeOut)
    );
  };
  updateGpsCache() {
    this.getGpsCoordinates(true).then((gps: any) => {
      let gpsRecord = { gps: gps, recorded_at: new Date().getTime() / 1000 };
      // this.checkGeoFenceAlertData(gps);
      localStorage.setItem('gpsRecord', JSON.stringify(gpsRecord));
      if (this.locationDisabled && gps?.lat) {
        this.locationDisabled = false;
        this.deviceInfoSubject.next({
          permissionStatus: 'locationEnabled',
        });
      }
    });
  }
  async getDeviceDetail(gpsCondition = 'must') {
    return new Promise(async (resolve) => {
      if (gpsCondition === 'must') {
        resolve(this.getDeviceDetailBasedOnCondition());
      } else if (gpsCondition === 'optional') {
        const hasPermission: any = await this.deviceHasGivenGpsPermission();

        if (hasPermission) {
          resolve(this.getDeviceDetailBasedOnCondition());
        } else {
          resolve(this.getDeviceDetailBasedOnCondition(false));
        }
      } else {
        resolve(this.getDeviceDetailBasedOnCondition(false));
      }
    });
  }

  async getDeviceDetailBasedOnCondition(addGps = true) {
    return new Promise(async (resolve) => {
      if (addGps) {
        let gps: any = await this.getGpsCoordinates();
        let gpsRecord = {
          gps: gps,
          recorded_at: new Date().getTime() / 1000,
        };
        localStorage.setItem('gpsRecord', JSON.stringify(gpsRecord));
        const deviceInfo: any = await this.getDeviceInfo();
        resolve({
          gps: {
            ...gps,
            ip: await this.getIp(),
          },
          ...deviceInfo,
        });
      } else {
        const deviceInfo: any = await this.getDeviceInfo();
        resolve({
          gps: {
            ip: await this.getIp(),
          },
          ...deviceInfo,
        });
      }
    });
  }
  // checkGeoFenceAlertData(userLocation: any) {
  //   const geoFenceAlertData = JSON.parse(
  //     localStorage.getItem(btoa('geoFenceAlertData')) || '[]'
  //   );

  //   function fetchHaversineDistance(alertData: any) {
  //     const haversineDistance: any = getDistanceUsingHaversine(
  //       userLocation?.lat,
  //       alertData?.gps?.lat,
  //       userLocation?.lon,
  //       alertData?.gps?.lon
  //     );
  //     return haversineDistance * 1000;
  //   }

  //   const now = new Date().getTime();
  //   const alertData = geoFenceAlertData
  //     ?.map((alertData: any) => {
  //       const haversineDistance = fetchHaversineDistance(alertData);
  //       return { ...alertData, haversineDistance: haversineDistance };
  //     })
  //     .filter(
  //       (alertData: any) =>
  //         alertData?.startDateTime <= now &&
  //         now <= alertData?.endDateTime &&
  //         alertData?.haversineDistance > Number(alertData?.geoFenceDistance)
  //     );
  //   this.deviceInfoSubject.next({
  //     geoFenceAlert: alertData,
  //   });
  // }
}
